import throttle from 'lodash.throttle'
import MutationSummary from 'mutation-summary'
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

const initHorizontalScroll = scroll => {
  const scrollArea = scroll.querySelector('.horizontal-scroll-area')
  const prevButton = scroll.querySelector('.horizontal-scroll-button[value="-1"]')
  const nextButton = scroll.querySelector('.horizontal-scroll-button[value="1"]')

  if (!prevButton || !nextButton) {
    console.warn('One or both scroll buttons missing in horizontal scroll. Bailing')
    return
  }

  const handleScroll = () => {
    prevButton.disabled = !scrollArea.scrollLeft
    nextButton.disabled = Math.round(scrollArea.offsetWidth + scrollArea.scrollLeft) >= scrollArea.scrollWidth
  }

  scrollArea.addEventListener('scroll', throttle(handleScroll, 66))
  handleScroll()

  const resizeObserver = new ResizeObserver(handleScroll)
  resizeObserver.observe(scrollArea)

  const handleButtonClick = e =>
    scrollArea.scrollBy({
      left: scroll.offsetWidth * e.currentTarget.value,
      behavior: 'smooth',
    })

  prevButton.addEventListener('click', handleButtonClick)
  nextButton.addEventListener('click', handleButtonClick)
}

const init = () => {
  document.querySelectorAll('.horizontal-scroll').forEach(initHorizontalScroll)
  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(initHorizontalScroll)
    },
    queries: [{ element: '.horizontal-scroll' }]
  })
}

document.addEventListener('DOMContentLoaded', init)
