import MutationSummary from 'mutation-summary'

const formatUrl = url => {
  const [ protocol, rest, ...extras ] = url.split('//')
  if (!rest || extras.length) return url

  return [
    protocol,
    rest.replace(/(:)/g, '$1<wbr>')
        .replace(/([/~.,\-_?#%+])/g, '<wbr>$1')
        .replace(/([=&])/g, '<wbr>$1<wbr>')
  ].join('//<wbr>')
}

const insertWbr = element => {
  if (element.innerHTML != element.textContent) return

  element.innerHTML = formatUrl(element.innerHTML)
}

const init = () => {
  document.querySelectorAll('.text-wrap-anywhere').forEach(insertWbr)
  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(insertWbr)
    },
    queries: [{ element: '.text-wrap-anywhere' }]
  })
}

document.addEventListener('DOMContentLoaded', init)
