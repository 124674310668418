// Modules
import 'src/application/fancy-menu/menu'
import 'src/application/clipboard'
import 'src/application/carousel'
import 'src/application/popover'
import 'src/application/sticky'
import 'src/application/dots'
import 'src/application/horizontal-scroll'
import 'src/application/text-wrap-anywhere'
import 'src/application/table-stacked'
import 'src/application/marquee'

// The rest
import Rails from '@rails/ujs'

Rails.start()

if (
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
)
  document.documentElement.classList.add('ios')

document.documentElement.classList.add(
  'ontouchstart' in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints
  ? 'touch' : 'no-touch'
)
